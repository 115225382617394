<template>
	<div>
		<div class="home">
			<div>
				<div style="text-align: center;z-index: 2;position: relative;" v-if="showGuide">
					<el-image src="/static/images/share/guide-1.png" style="width: 80%;position: fixed;top:2%;right: 8%;"/>
				</div>
				<el-image src="/static/images/share/background-3.png" style="width: auto"/>
				<div style="text-align: center;position: relative;bottom: 140px">
					<div @click="toMiniApp">
						<!--小程序-->
						<el-image src="/static/images/share/botton-1.png" style="width: 80%"/>
					</div>
					<div style="margin-top: 3%" @click="toDown">
						<!--下载APP-->
						<el-image src="/static/images/share/botton-2.png" style="width: 80%"/>
					</div>
				</div>

				<div id="RQCode-div" v-if="showRQCode">
					<el-image src="/static/images/share/miniapp-RQCode.jpg" style="width: 80%;padding-top: 10%"/>
					<div style="padding-bottom: 5%">
						长按识别二维码
					</div>
				</div>

			</div>
		</div>
		<!--遮罩层-->
		<div class="top-div" v-if="showShade" @click.stop="closeShade"></div>
	</div>
</template>

<script>

	export default {
		name: 'sharePage',
		components: {},
		data() {
			return {
				showShade: false,
				showRQCode: false,
				showGuide: false,
			}
		},
		created() {
			console.log(this.appSource());
		},
		methods: {
			appSource() {
				const u = navigator.userAgent;
				const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
				if (isiOS) {
					return "ios";
				} else {
					return "android";
				}
			},
			toMiniApp() {
				// 判断是否在微信中
				if (this.isWeiXin()) {
					// window.location = "https://inner.h5.weseego.com/scan/mp/wxtravel";
					this.showRQCode = true;
					this.showShade = true;
				} else {
					// 提示
					this.$message({
						message: '请复制到微信中打开！',
						type: 'warning'
					});

				}
			},
			isWeiXin() {
				var ua = window.navigator.userAgent.toLowerCase();
				let isWx = ua.match(/MicroMessenger/i) == "micromessenger";
				if (ua.match(/WxWork/i) == "wxwork") {
					return false;
				} else if (isWx) {
					return true
				}
			},
			isSafari() {
				//判断非SAFA时用这个

				if (!(/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent))) {
					return false;
				} else if ((/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent))) {
					return true
				}
			},
			toDown() {
				let appSource = this.appSource();
				if (appSource === 'ios') {
					// this.$message('当前是IOS');
					if (!this.isSafari()) {
						// this.$message('请复制到浏览器打开');
						this.showShade = true;
						this.showGuide = true;
						return
					}
					this.toAppStore();
					// } else if (appSource === "android") {
					// 	// 提示
					// 	this.$message('敬请期待！android平台暂无上架！');
					// }
				} else {
					this.$message('android平台即将上线！敬请期待！');
				}
			},
			toAppStore() {
				window.location.href = "https://itunes.apple.com/cn/app/weixinglvyou/id1573548275";
			},
			closeShade() {
				this.showRQCode = false;
				this.showGuide = false;
				this.showShade = false;
			}
		}
	}
</script>

<style lang="scss">
	body {
		background-color: #2868c3;
		background-size: cover; /*设置图片大小*/
		/*height: 100%;*/
	}

	.home {
		height: 100vh;
		max-width: 750px;
		margin: 0 auto;
		/*background-image: url(/static/images/share/background-3.png);*/
		/*background-repeat: repeat-x;*/
		/*background-size: 100% auto; !*设置图片大小*!*/


	}

	.content {
		height: 100vh;
		/*text-align: center;*/
		margin-top: 10%;
		margin-left: 6%;
		margin-right: 6%;

		background-image: url(/static/images/share/background-2.png);
		background-repeat: repeat-x;
		background-size: 100% auto; /*设置图片大小*/
	}

	.content-logo {
		width: 30%;
		padding-top: 3%;
		padding-bottom: 4%;
		padding-left: 3%;
	}

	.content-image {
		margin-right: 3%;
		margin-left: 3%;
	}

	.content-button {
		text-align: center;
		margin-top: 16%;
	}

	#button-1 {
		width: 40%;
		color: white;
		font-size: 17px;
		font-weight: bold;
		background-color: #F5A623;
	}

	.button-1-icon {
		background: url("/static/images/share/button1.png") center no-repeat;
		font-size: 16px;
		background-size: cover;
	}

	.button-1-icon:before {

	}

	#button-2 {
		width: 40%;
		color: white;
		font-size: 17px;
		font-weight: bold;
		background-color: #2779BD;
	}

	.top-div {
		height: 100vh;
		width: 100%;
		z-index: 1;
		background-color: rgba(0, 0, 0, .7);
		position: fixed;
		top: 0;
	}

	#RQCode-div {
		z-index: 2;
		background-color: white;
		width: 80%;
		position: relative;
		bottom: 500px;
		left: 50%;
		transform: translate(-50%, -50%);
		text-align: center;
		border-radius: 20px
	}

</style>
